<template>
    <div>
        
        <a-modal
        v-model="modals"
        @ok="handleOk"
        :footer="null"
        width:="640px"
        :centered="true"
        
        >
            <div slot="title">
                <div class="titlemodalaction">
                    <img :src="require(`@/assets/img/icon/${iconStatus == 'Delete' ? 'delete.svg' : (iconStatus == 'Reaktif' ? 'reaktif.svg' : ( (iconStatus == 'Mutasi' ? 'mutasi.svg' : 'reprint.svg') ) )}`)" />
                <h4> {{ iconStatus == 'Delete' ? 'Hapus': (iconStatus == 'Reaktif' ? 'Reaktif' : (iconStatus == 'Mutasi' ? 'Mutasi' : 'Reprint') )  }} Data Peserta</h4>
                </div>
            </div>
         
          <a-form :form="form" @submit="Submit">

            <a-form-item label="Tgl Efektif">
                 <a-date-picker
                        :disabled-date="iconStatus == 'Delete' ? disabledDate : false"
                        autocomplete="off"
                        v-decorator="['tgl_efektif',{
                          rules:[{
                            required:true,
                            message:'Field ini harus diisi'
                          }]  
                        }]"
                        style="width: 100%"
                        format="DD MMM YYYY"
                        
                      />
            </a-form-item>
            <a-form-item label="Nama Perusahaan" v-if="iconStatus == 'Mutasi'" >
                <a-select v-decorator="[
                      'nama_perusahaan',
                      {
                        rules: [
                          { required: true, message: 'Field ini harus diisi' },
                        ],
                      },
                    ]"
                placeholder="Nama Perusahaan">
                <a-select-option
                      v-for="(item, i) in perusahaan"
                      :key="i"
                      :value="item.id"
                      :disabled="item.id == idperusahaan ? true:false"
                      >{{ item.nama_perusahaan }}</a-select-option
                    >
                 
            </a-select>
            
            </a-form-item>
            <a-form-item label="Nama Peserta">
                <a-select mode="multiple"
                v-decorator="['id_peserta',{
                    initialValue:id
                }]"
                :disabled="true">
                <a-select-option v-for="(item,i) in id" :key="i" :value="item">
                    {{ peserta[i] }}
                </a-select-option>
                </a-select>
            </a-form-item>
            <div style="display: flex; justify-content: right; margin-bottom: 15px;">
                <a-button type="primary" html-type="submit" :loading="isLoading">Kirim</a-button>
            </div>
            </a-form>
        </a-modal>
    </div>
</template>

<script>
import moment from 'moment'
export default {
    beforeCreate(){
        this.form = this.$form.createForm(this,{name:'FormAction'})
    },
    data() {
        return {
            modals: false,
            fileList: [],
            iconStatus: null,
            id: [],
            peserta: [],
            isLoading: false,
            perusahaan: [],
            idperusahaan: '',
             datedisable:0
        }
    },
      mounted() {
          document.body.classList.add("ModalCustom");
        
         
        
  },
  destroyed() {
    document.body.classList.remove("ModalCustom");
  },
    methods: {
        moment,
        beforeUpload(){},
        handleRemove(){},
        handleOk() {
            this.modals = false
        },
        handleCancel() {
            this.modals = true            
        },
        getPerusahaan() {
            // action: 'MUTASI',
            // id_peserta: 
        this.$store
            .dispatch("GetDaftarPerusahaanMutasi", {
            page: 1,
            pageSizes: 15,
            id: this.$route.params.id,
            
            })
            .then((response) => {
            this.perusahaan = response.data.data;
            
            });
        },
        disabledDate(current) {
            //console.log(current)
             return current && current <= moment().subtract((parseInt(this.datedisable) + 1),"days");
        },
        datedisabled(item) {
            if(item =='Delete'){
                this.$store.dispatch('getDateDisabled', {
                    movement: item.toUpperCase(),
                    job_order:this.$route.params.id
                }).then(response => {
                    this.datedisable = response.data.data.ketentuan
                    //console.log(moment().subtract(this.datedisable,"days"))
                })
            } else {
                this.datedisable = 0
            }
       },

        Show(item,id,namapeserta,idperusahaan) {
            this.modals = true
            this.iconStatus = item
            this.idperusahaan = idperusahaan
            this.datedisabled(item)
            if (item == 'Mutasi') {
                  this.getPerusahaan();
            }

            if (typeof namapeserta == 'string') {
                this.peserta = [namapeserta]
                this.id = [id]
            } else {
                this.peserta = namapeserta
                this.id = id
            }
            this.form.resetFields()
            
        },
        Submit(e) {
            e.preventDefault()
           
            this.form.validateFields((err, values) => {
                if (!err) {
                    this.isLoading = true

                  
                    if (this.iconStatus == 'Mutasi') {

                          //console.log('icon',values)
                        this.$store.dispatch('ActionDataPeserta', {
                        id_peserta: values.id_peserta,
                        tgl_efektif: moment(values.tgl_efektif).format("YYYY-MM-DD"),
                        link: this.iconStatus.toLowerCase(),
                        id: this.$route.params.id,
                        nama_perusahaan: values.nama_perusahaan
                    }).then(response => {
                        this.$notification.success({
                            message:response.data.message
                        })
                        this.isLoading = false
                        this.$router.push({name:'ListQueAntrianData'})
                        this.modals = false
                    })

                    } else if (this.iconStatus == "Reaktif" && this.$route.query.menu == "coming-movement") {
                        this.$store.dispatch('ActionPesertaBeforeUpdate', {
                        id: Array.isArray(this.id) ? this.id : [this.id],
                        tgl_efektif: moment(values.tgl_efektif).format("YYYY-MM-DD"),
                        id_job_order:this.$route.params.id
                    }).then(response => {
                        this.$notification.success({
                            message:response.data.message
                        })
                        this.isLoading = false
                        this.$router.push({name:'ListQueAntrianData'})
                        this.modals = false
                    })
                    } else {
                        this.$store.dispatch('ActionDataPeserta', {
                        id_peserta: values.id_peserta,
                        tgl_efektif: moment(values.tgl_efektif).format("YYYY-MM-DD"),
                        link: this.iconStatus.toLowerCase(),
                        id:this.$route.params.id
                    }).then(response => {
                        this.$notification.success({
                            message:response.data.message
                        })
                        this.isLoading = false
                        this.$router.push({name:'ListQueAntrianData'})
                        this.modals = false
                    })
                    }
                    
                }
            })
        }
    }
}
</script>

<style>
.ModalCustom .ant-modal-content{
    padding:2px 5px !important;
   
   
}

.ModalCustom  .ant-modal-header{
 border-radius: 24px !important;

 }

 .ModalCustom .ant-modal-close-x{
    margin-right: 20px;
 }

</style>
